import assets from '../../assets';

const {
  fBilling,
  fDirectory,
  fProfile,
  fScheduling,
  playStore,
  googleAssitant,
  zoom,
  alexa,
  fImageGooglePlayBadge,
  fImagemAppleStoraBadge,
} = assets;

const jr = 'images/react/test/jw-rayhons.png';
const tm = 'images/react/test/traci-mccausland.png';
const dl = 'images/react/test/dawn-landry.png';
const jk = 'images/react/test/jon-kovac.png';
const jw = 'images/react/test/jeff-williamson.png';

export const TESTIMONIALS = [
  {
    name: 'JW Rayhons',
    company: 'Joshua Development',
    text: '"I recommend joining Soar to grow your coaching business with tools that can help you be more effective, and a network of professional coaches around the world."',
    avatar: jr,
  },
  {
    name: 'Traci McCausland',
    company: 'Follow Your Strengths',
    text: '"The coaches, consultants, and trainers involved in Soar are bright, driven, and loyal. I’ve really enjoyed the connections, collaboration, and friendships I’ve built by engaging and growing with the Soar team."',
    avatar: tm,
  },
  {
    name: 'Dawn Landry',
    company: 'Authentizity',
    text: '"I joined Soar because I truly believe in its vision to offer a platform in which anyone, anywhere can reach their best human potential. The sky is the limit with Soar!"',
    avatar: dl,
  },
  {
    name: 'Jon Kovac',
    company: 'Amplified Minds',
    text: '"I have absolutely loved my experience working with Paul Allen and his partners. Through Soar, I\'ve built friendships and partnerships with several coaches who\'ve all helped me scale my business."',
    avatar: jk,
  },
  {
    name: 'Jeff Williamson, Ed. D.',
    company: 'CMG Group',
    text: '"Soar has enhanced my personal and professional life the last 2 years with dozens of rich, authentic connections with other coaches and terrific people. I look forward to the future serving with Soar."',
    avatar: jw,
  },
];

export const PLUS_FEATURES = [
  {
    title: 'Videoconferencing - any way you like',
    text: 'Soar now allows you to use your meeting room link from any video platform when scheduling appointments. Connect your Zoom account, and have instant access to your recordings on the Soar Media App.',
    img: zoom,
  },
  {
    title: 'Automated Scheduling',
    text: 'Let your clients easily schedule with you. Seamlessly sync with your Google or Outlook Calendar!',
    img: fScheduling,
  },
  {
    title: 'Client Invoicing',
    text: 'Easily invoice clients in just a few clicks. Accept multiple payment formats. Get paid via direct deposit.',
    img: fBilling,
  },
];

export const FREE_FEATURES = [
  {
    title: 'Coach Directory',
    text: 'Appear in Soar Coach Directory.',
    img: fDirectory,
  },
  {
    title: 'Social Media Content',
    text: 'Free daily Social Media Content to promote yourself.',
    img: fProfile,
  },
  {
    title: 'Zoom Transcripts',
    text: 'Free searchable transcripts of all your Zoom calls(on your smartphone).',
    img: zoom,
  },
];

export const GROWTH_FEATURES = [
  {
    title: 'Zoom Recordings & Transcripts',
    text: 'Automatically get all of your zoom recordings and transcripts, accessible on any device.',
    img: zoom,
  },
  {
    title: 'Mobile App (Android & iPhone)',
    text: 'All of your Zoom recordings & transcripts easily searchable and streamable on your smartphone.',
    img: playStore,
    imgBottomLeft: fImageGooglePlayBadge,
    imgBottomRight: fImagemAppleStoraBadge,
    urlLeft: 'https://play.google.com/store/apps/details?id=com.soar.soarlibrary',
    urlRight: 'https://apps.apple.com/us/app/soar-audio/id1502457902',
  },
  {
    title: 'Listen to your calls on Alexa or Google Assistant',
    text: 'Recall powerful moments from your coaching conversations while driving, exercising or walking.',
    img: alexa,
    imgAux: googleAssitant,
  },
];

export default {
  logo: 'images/react/logo.png',
  landingLaptop: 'images/react/landing-laptop.png',
  landingLaptopMobile: 'images/react/landing-laptop-mobile.png',
  tickLeft: 'images/react/tick-left.svg',
  tickRight: 'images/react/tick-right.svg',
  fBilling: 'images/react/feature-billing.svg',
  fDirectory: 'images/react/feature-directory.svg',
  fLibrary: 'images/react/feature-library.svg',
  fProfile: 'images/react/feature-profile.svg',
  fScheduling: 'images/react/feature-scheduling.svg',
  fVideoConf: 'images/react/feature-video-conference.svg',
  gAssistant: 'images/react/growth-assistant.svg',
  gLead: 'images/react/growth-lead-gen.svg',
  gMore: 'images/react/growth-more.svg',
  pa: 'images/react/paul-allen.png',
  invest: 'images/react/invest.png',
  soarAudio: 'images/react/soar_audio.jpg',
  soarAudioMobile: 'images/react/soar_audio_m.jpg',
  facebook: 'images/react/facebook.svg',
  twitter: 'images/react/twitter.svg',
  linkedin: 'images/react/linkedin.svg',
  zoom: 'images/react/zoom.svg',
  appleStore: 'images/react/apple-store-custom.svg',
  playStore: 'images/react/feature-mobile.png',
  alexa: 'images/react/alexa-logo.svg',
  googleAssitant: 'images/react/google-assistant-logo.svg',
  fImageGooglePlayBadge: 'images/react/google-play-badge.png',
  fImagemAppleStoraBadge: 'images/react/apple-store-badge.png',
};

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FREE_FEATURES, PLUS_FEATURES, GROWTH_FEATURES, TESTIMONIALS } from '../../shared/constants/site-constants';
import Carousel from './carousel';
import Style from './styles';
import assets from '../../assets';
import './theme.scss';
import { isProduction } from '../../shared/helpers/request-helper';

const {
  landingLaptop,
  landingLaptopMobile,
} = assets;

const signUpURL = `https://${isProduction ? 'app' : 'staging'}.soar.com/pro`;

const Landing = () => (
  <Style>
    <div className="intro">
      <div className="action">
        <h1>
          Try Soar&apos;s Audio Suite for Coaches.
        </h1>
        <img src={landingLaptopMobile} alt="SOAR" />
        <p className="sub-text">
          You coach with profound impact in the moment.
          Now, give your clients LIFELONG access to searchable
          audio recordings of all your sessions, available on any device.
        </p>
        <p className="full-width">
          <a href={signUpURL} className="btn-join">Join Soar For Free</a>
        </p>
      </div>
      <img src={landingLaptop} alt="SOAR" />
    </div>

    <div className="plus-features">
      <h4>Free Features</h4>
      <section className="card-section">
        {FREE_FEATURES.map((feature, i) => (
          <div key={i} className="card">
            <div>
              <img src={feature.img} alt={feature.title} width={75} />
              {feature.imgAux !== undefined ? (
                <img src={feature.imgAux} alt={feature.title} width={75} style={{ marginLeft: '10px' }} />
              ) : null}
            </div>
            <h5>{feature.title}</h5>
            <p>{feature.text}</p>
          </div>
        ))}
      </section>
    </div>
    <div className="plus-features">
      <h4>
        <span className="highlight-orange">Premium</span> Subscriber Benefits
      </h4>
      <section className="card-section">
        {PLUS_FEATURES.map((feature, i) => (
          <div key={i} className="card">
            <div>
              <img src={feature.img} alt={feature.title} width={75} />
              {feature.imgAux !== undefined ? (
                <img src={feature.imgAux} alt={feature.title} width={75} style={{ marginLeft: '10px' }} />
              ) : null}
            </div>
            <h5>{feature.title}</h5>
            <p>{feature.text}</p>
          </div>
        ))}
      </section>
    </div>
    <div className="growth-features">
      <h4>
        Soar <span className="highlight-blue">Audio Suite</span>
      </h4>
      <h4>
        You and your clients get...
      </h4>
      <section className="card-section">
        {GROWTH_FEATURES.map((feature, i) => (
          <div key={i} className="card">
            <div>
              <img src={feature.img} alt={feature.title} width={75} />
              {feature.imgAux !== undefined ? (
                <img src={feature.imgAux} alt={feature.title} width={75} style={{ marginLeft: '10px' }} />
              ) : null}
            </div>
            <h5>{feature.title}</h5>
            <p>{feature.text}</p>
            { feature.imgBottomLeft !== undefined ? (
              <div style={{ display: 'flex' }}>
                <a href={feature.urlLeft}>
                  <img src={feature.imgBottomLeft} alt={feature.title} width={125} style={{ marginTop: '20px', cursor: 'pointer' }} />
                </a>
                { feature.imgBottomRight !== undefined ? (
                  <a href={feature.urlRight}>
                    <img src={feature.imgBottomRight} alt={feature.title} width={125} style={{ marginTop: '20px', marginLeft: '20px', cursor: 'pointer' }} />
                  </a>
                ) : null}
              </div>
            ) : null}
            {feature.title === 'And More!' ? (
              <a href={signUpURL} className="get-started-btn">Get started today →</a>
            ) : null}
          </div>
        ))}
      </section>
    </div>

    <div className="testimonials">
      <h4>testimonials</h4>
      <p>
        {'Trusted by '}
        <span className="highlight-primary">over 4,500</span>
        {' professionals worldwide.'}
      </p>

      <Carousel testimonials={TESTIMONIALS} />
    </div>

    <div className="get-started">
      <h4>get started</h4>
      <h2>Create your account today!</h2>
      <a href={signUpURL} className="btn-join">
        Join Soar Today
      </a>
    </div>
  </Style>
);

export default Landing;

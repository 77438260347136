/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// import Style from './styles';
import assets from '../../../assets';
import './carousel.scss';

const { tickLeft, tickRight } = assets;

const Carousel = ({ testimonials }) => {
  const ref = useRef(null);

  let pos = 0;
  let timer;

  const scroll = () => {
    if (!ref.current) return;
    ref.current.scrollTo({
      behavior: 'smooth',
      left: window.innerWidth <= 1000 ? window.innerWidth * pos : 700 * pos,
    });
  };

  const scrollRight = () => {
    if (!ref.current) return;
    resetTimer();
    pos = pos >= testimonials.length - 1 ? testimonials.length - 1 : pos + 1;
    scroll();
  };

  const resetTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => {
      if (!ref.current) return;
      if (pos >= testimonials.length - 1) {
        pos = 0;
        ref.current.scrollTo({
          behavior: 'smooth',
          left: 0,
        });
      } else scrollRight();
    }, 5000);
  };

  const scrollLeft = () => {
    if (!ref.current) return;
    resetTimer();
    pos = pos <= 0 ? 0 : pos - 1;
    scroll();
  };

  useEffect(() => {
    resetTimer();
    return () => {
      clearInterval(timer);
    };
  });

  return (
    // <Style>
    <div className="carousel">
      <button type="button" className="carousel left" onClick={scrollLeft}>
        <img src={tickLeft} alt="" />
      </button>
      <button type="button" className="carousel right" onClick={scrollRight}>
        <img src={tickRight} alt="" />
      </button>

      <section className="section carousel" ref={ref}>
        <span>
          {testimonials.map((data, i) => (
            <div className="testimonial" key={i}>
              <h3>{data.text}</h3>
              <img src={data.avatar} alt={data.name} />
              <h5>{data.name}</h5>
              <p>{data.company}</p>
            </div>
          ))}
        </span>
      </section>
    </div>
    // </Style>
  );
};

Carousel.propTypes = {
  testimonials: PropTypes.any,
};

Carousel.defaultProps = {
  testimonials: [],
};

export default Carousel;

import { connect } from 'react-redux';
import Site from '../components/index';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Site);

import axios from 'axios';

export const isProduction = window.location.hostname === 'www.soar.com';

export async function fetchJson(url, options = {}) {
  const response = await fetch(url, options);
  const json = await response.json();
  return json;
}

export function getSoarApiUrl() {
  const apiUrlElem = document.getElementById('api-url');
  let soarApiHost = apiUrlElem && apiUrlElem.value;

  if (!soarApiHost) {
    soarApiHost = isProduction ? 'https://api.soar.com/v1' : 'https://stage.api.soar.com/v1';
  }

  return soarApiHost;
}

export function getSoarAppUrl() {
  const apiUrlElem = document.getElementById('app-url');
  let soarApiHost = apiUrlElem && apiUrlElem.value;

  if (!soarApiHost) {
    soarApiHost = isProduction ? 'https://soar.com' : 'https://stage.soar.com';
  }

  return soarApiHost;
}

export async function sendMessage(url, payload, successCallback, failureCallback) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: payload,
    });
    const data = await response.json();

    if (response.status === 200 && data === 'Success') {
      successCallback();
    } else {
      throw new Error(data);
    }
  } catch (error) {
    failureCallback(error);
  }
}

export async function isResourceAvailable(url) {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error) {
    return false;
  }
}

export async function generateJWT(failureCallback) {
  try {
    const urlAuth = process.env.REACT_APP_AUTH0_DOMAIN;
    const body = JSON.stringify({
      client_id: process.env.AUTH0_CLIENT_ID,
      client_secret: process.env.AUTH0_CLIENT_SECRET,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      grant_type: 'client_credentials',
    });

    const response = await axios.post(
      `https://${urlAuth}/oauth/token`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = response;
    if (response.status === 200) {
      localStorage.setItem('tokenTMP', data.access_token);
    } else {
      failureCallback(data);
    }
  } catch (error) {
    failureCallback(error);
  }
}

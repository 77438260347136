import styled from 'styled-components';

const mobile = 'screen and (max-width: 999px)';
const desktop = 'screen and (min-width: 1000px)';

const Style = styled.div`
  --header-height: 75px;

  min-height: calc(100vh - var(--header-height));
  padding-top: 40px;

  overflow: hidden;

  & > header {
    z-index: 50;

    position: fixed;
    top: 0;
    left: 0;

    height: 75px;
    width: 100%;

    background-color: var(--site-bg-white);
    border-bottom: 1px solid #ddd;
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .logo > img {
      height: 40px;
    }

    & > nav {
      flex: 1;
      display: flex;
      justify-content: center;

      @media ${mobile} {
        display: none;
      }

      & > a {
        margin: 0 1.2rem;
        padding: 3px 0;
      }
    }

    & > .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > p {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.11px;
        color: #000000 !important;
      }

      & > a {
        margin: 0 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: var(--site-text-gray);

        &.btn-register {
          background-color: var(--site-primary);
          color: white !important;
          height: 42px;
          width: 100px;
          border-radius: 21px;
        }

        &.btn-login {
          @media ${mobile} {
            display: none;
          }
        }
      }

      & > button.hamburger {
        height: 20px;
        width: 25px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${desktop} {
          display: none;
        }

        & > span {
          display: block;
          height: 4px;
          border-radius: 2px;
          width: 25px;
          background-color: black;
        }
      }
    }

    & > nav.o {
      flex: 1;
      display: flex;
      justify-content: center;

      @media ${mobile} {
        display: none;
      }

      & > a {
        margin: 0 1.2rem;
        padding: 3px 0;
      }
    }
  }

  & > .intro {
    display: flex;

    @media ${desktop} {
      flex-direction: row;
      justify-content: flex-end;
    }

    & > .action {
      padding: 0 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media ${desktop} {
        padding: 0 20px 0 150px;
        text-align: initial;
      }

      & > h1 {
        font-size: 21px;

        @media ${desktop} {
          font-size: 36px;
          width: 100%;
        }
      }

      & > img {
        width: 100%;
        max-width: 800px;

        @media ${desktop} {
          display: none;
        }
      }

      & > p {
        &.full-width {
          width: 100%;
        }

        @media ${desktop} {
          &.sub-text {
            font-size: 17px;
            width: 100%;
            padding-bottom: 60px;
          }
        }
      }

      & .btn-join {
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        background-color: var(--site-primary) !important;
        width: fit-content;
        padding: 20px 30px;
        border-radius: 100px;
        color: white !important;
        box-shadow: 0px 20px 15px -20px var(--site-primary);
      }
    }

    & > img {
      flex: 1;
      width: 50%;
      height: 50%;
      object-fit: contain;
      object-position: right center;

      @media ${mobile} {
        display: none;
      }
    }
  }

  & > .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 50px 0;

    & > p {
      font-size: 21px;
      text-align: center;
      padding: 0 15px;
    }
  }

  & > .testimonials.o {
    --testimonial-height: 470px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${mobile} {
      padding-top: 100px;
    }

    & > p {
      margin: 10px 0;
    }

    & > .carousel {
      --testimonial-width: 500px;
      --testimonial-padding: 100px;

      position: relative;
      height: var(--testimonial-height);
      display: flex;
      align-items: center;

      @media ${mobile} {
        --testimonial-width: calc(100vw - 80px);
        --testimonial-padding: 40px;
      }

      & > button {
        position: absolute;
        height: var(--testimonial-height);
        width: calc(
          (100vw - var(--testimonial-width) + (var(--testimonial-padding) * 2)) /
            2
        );
        background: var(--site-bg-white);
        opacity: 0.7;

        @media ${mobile} {
          width: var(--testimonial-padding);

          & > img {
            width: 100%;
          }
        }

        &.right {
          right: 0;

          & > img {
            float: left;
          }
        }

        &.left > img {
          float: right;
        }
      }

      & > section {
        width: 100vw;
        overflow-x: hidden;
        scroll-behavior: smooth;

        & > span {
          display: inline-flex;

          & > .testimonial {
            --testimonial-width-and-padding: calc(
              var(--testimonial-width) + var(--testimonial-padding)
            );

            width: var(--testimonial-width);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 var(--testimonial-padding);

            &:first-child {
              margin-left: calc(
                (100vw - var(--testimonial-width-and-padding)) / 2
              );

              @media ${mobile} {
                margin-left: 0;
              }
            }
            &:last-child {
              margin-right: calc((100vw - 700px) / 2);

              @media ${mobile} {
                margin-right: 0;
              }
            }

            & > h3 {
              font-family: var(--site-font-ibm) !important;
              font-weight: 300;
              text-align: center;
            }

            & > h5 {
              font-size: 17px;
              font-weight: bold;
              margin: 5px 0;
            }

            & > p {
              font-size: 15px;
              color: #949494;
              margin: 5px 0;
            }

            & > img {
              height: 72px;
              width: 72px;
            }
          }
        }
      }
    }
  }

  & > .plus-features,
  & > .growth-features,
  & > .get-started {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .plus-features {
    & > h4 {
      & > .highlight-orange {
        color: var(--site-orange);
      }
      & > .highlight-blue {
        color: var(--site-blue);
      }
    }

    & > section {
      display: grid;
      grid-template: 1fr / repeat(3, 1fr);
      grid-gap: 14px;
      width: 70vw;
      margin: 50px 0 100px 0;

      @media ${mobile} {
        grid-template: repeat(3, 1fr) / 1fr;
      }
    }
  }

  & > .growth-features {
    & > section {
      display: grid;
      grid-template: 1fr / repeat(3, 1fr);
      grid-gap: 14px;
      width: 70vw;
      margin: 50px 0 0 0;

      @media ${mobile} {
        grid-template: repeat(3, 1fr) / 1fr;
      }
    }
  }

  & > .get-started {
    padding: 50px;
    text-align: center;

    & > h2 {
      font-weight: 500;
      margin: 50px 0;
    }

    & > a {
      font-size: 19px;
    }

    & > .btn-join {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      background-color: var(--site-primary);
      width: fit-content;
      padding: 20px 30px;
      border-radius: 100px;
      color: white !important;
      box-shadow: 0px 20px 15px -20px var(--site-primary);
    }
  }

  & > .invest {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px 0px 50px 0px;

    @media ${desktop} {
      margin: 5px 0;
    }

    & > .invest-card {
      max-width: 1000px;
      margin: 0 50px;
      background: white;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;

      @media ${desktop} {
        flex-direction: row-reverse;
        height: 550px;
      }

      @media ${mobile} {
        margin: 0 26px;
      }

      & > img {
        flex: 1;
        object-fit: cover;
        object-position: left;
        width: 100%;
        height: 175px;

        @media ${desktop} {
          height: 100%;
        }
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 70px 45px;

        & > h1 {
          font-size: 21px;
          margin: 0;

          @media ${desktop} {
            font-size: 36px;
          }
        }

        & > h5 {
          font-size: 17px;
          margin: 10px 0 4px 0;
        }

        & > img {
          height: 72px;
          width: 72px;
        }

        & > p {
          margin: 12px 0 32px 0;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.11px;
          text-align: center;
          color: #000000 !important;
        }

        & > small {
          color: #949494;
          font-size: 15px;
        }

        & > a {
          color: var(--site-orange-dark) !important;
          font-weight: bold;
          border: 1px solid var(--site-orange-dark);
          font-size: 19px;
          padding: 20px 30px;
          min-width: 150px;
          text-align: center;
          margin-top: 32px;
          border-radius: 32px;
        }
      }
    }
  }

  & > footer {
    box-shadow: 0 -0.3px 0 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 32px;
    position: relative;

    @media ${desktop} {
      flex-direction: row;
      padding: 50px 0;
    }

    & > .col {
      width: 150px;
      font-size: 11px;

      @media ${mobile} {
        margin-left: 30px;
      }

      &.social-links {
        display: flex;
        flex-direction: row-reverse;

        @media ${mobile} {
          position: absolute;
          right: 32px;
          top: 32px;
        }

        & > a {
          margin: 0 5px;
        }
      }

      & > * {
        margin-bottom: 32px;
      }

      & > h4 {
        margin-bottom: 8px;
      }

      & > ul {
        list-style: none;
        padding: 0;

        & > li {
          margin: 10px 0;
        }
      }
    }
  }

  & .card {
    border-radius: 9px;
    background: white;
    padding: 66px 24px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    & > h5 {
      margin: 24px 0 8px 0;
      font-weight: bold;
      font-size: 17px;
    }

    & > p {
      margin: 0;
      font-size: 15px;
    }

    & > a {
      display: flex;
      width: fit-content;
      color: var(--site-primary);
      margin-top: 10px;
    }
  }

  & h4 {
    font-size: 20px;
    text-transform: uppercase;
    color: var(--site-text-gray);
    font-weight: 500;
    margin: 0;
  }

  & .highlight-primary {
    color: var(--site-primary-dark);
    font-weight: bold;
  }
`;

export default Style;
